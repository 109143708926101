import React from "react";
import Layout from "../styles/layout";
import { Typography, Container } from "@mui/material";
import Seo from "../components/seo";

export default function PrivacyPolicy({ props, location }) {
  return (
    <Layout location={location}>
      <Seo
        title="Impressum"
        description="Legal impressum with information about author and owner of this site."
      />
      <Container>
        <Typography variant="h1">Impressum</Typography>
        <Typography variant="h3">Author and owner of this site</Typography>
        <Typography>
          Natascha Fadeeva
          <br />
          August-Bebel-Str. 18 04275 Leipzig Germany
          <br />
          Contact: tanaschita@gmail.de
          <br />
          USt-ID-Nr: DE305912087
          <br />
        </Typography>
      </Container>
    </Layout>
  );
}
